.container {
    width: min(100vw, 1280px);
    height: -webkit-fill-available;

    margin-left: auto;
    margin-right: auto;

    padding-top: 20vh;

}

.item {
    flex: 1;
}